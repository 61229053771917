export const csvModalMsg = {
	book: "ブック設定",
	chapter: "チャプター設定",
	subView: "拡大画面設定",
	editor: "MY教科書エディタ設定",
	audio: "音声読み上げ設定(国語のみ)",
	html: "HTMLコンテンツ設定",
	video: "動画設定",
};

export const  csvColumnsName = {
	flag: "編集",
	type: "種類",
	page: "ページ",
	id: "ID",
	originId: "元ID",
	thumbKey: "サムネイルID",
	"css.backgroundColor": "背景色",
	"css.top": "Y座標",
	"css.left": "X座標",
	"css.width": "サイズ(横)",
	"css.height": "サイズ(縦)",
	"data.page": "移動ページ",
	"options.iFrame": "コンテンツ",
	"options.link.level": "レイヤー番号",
	"options.link.zIndex": "レイヤー優先度",
	"data.file": "ファイル名",
	"data.fileKey": "R-ID",
	"data.subtitle": "字幕名",
	"data.subtitleKey": "R-ID(字幕)",
	"data.tooltip": "吹き出し",
	"data.title": "タイトル",
	"editor.ignore": "MY教科書エディタ登録",
	"data.zipKey": "R-ID（HTMLコンテンツ)",
	"data.htmlFileName": "HTMLファイル名",
	"contentId": "テキストアイディー",
	"value": "テキスト",
	"css.fontSize": "テキストサイズ",
	"css.lineHeight": "行間隔",
	"css.marginLeft": "X座標",
	"css.marginTop": "Y座標",
	"editor.myEditorImage": "R-ID(文字・背景画像)",
	"editor.myEditorImage_bg": "R-ID(背景画像)",
	"data.playAt": "転換後再生時間",
	"data.moveAt": "ページ転換時間",
	"data.index": "順番",
	"data.kakezu": "掛図モード順番",
	videoFileKey: "R-ID(動画)",
};

/** @type {{}} video csv 의 컬럼을 위한 상수객체  */
export const vColumn = {
	originId: "元ID",
	id: "ID",
	page: "ページ",
	"css.left": "X座標",
	"css.top": "Y座標",
	"data.fileKey": "R-ID(動画)",
	"data.subtitleKey": "R-ID(字幕)",
	"data.tooltip": "吹き出し",
	"data.title": "タイトル",
	thumbKey: "サムネイルID",
	flag: "編集",
};


export const svColumn = {
	meaningRed: "意味段落赤線",
	meaningBlue: "意味段落青線",
	originId: "元ID",
	id: "ID",
	flag: "編集",
	type: "表示モード", // csvColumnsName.type 충돌
	page: "ページ番号", // csvColumnsName.page 충돌
	"data.page": "リンク先ページ",
	"options.iFrame": "HTMLコンテンツ", //  csvColumnsName 충돌
	"options.link.level": "レイヤー番号",
	"options.link.zIndex": "レイヤー優先度",
	"css.top": "Y座標",
	"css.left": "X座標",
	"css.width": "サイズ(横)",
	"css.height": "サイズ(縦)",
	"css.opacity": "透明度",
	"data.class": "教科書別(アイコン種類）",
	"data.type": "イベント",
	"data.kakezu": "掛図モード順番",
	"data.title": "タイトル",
};

export const csvConst = {
	editor: "editor",
	undefined: "undefined",
	page: "page",
	objects: "objects",
	icon: "icon",
	dataClass: "data.class",
	dataPath: "data.path",
	dataType: "data.type",
	cssPosition: "css.position",
	absolute: "absolute",
	subView: 'subView',
	originId: "originId",
	id: "id",
	css: "css",
	height: "height",
	left: "left",
	opacity: "opacity",
	top: "top",
	width: "width",
	data: "data",
	class: "class",
	flag: "flag",
	type: "type",
	show: "show",
	options: "options",
	link: "link",
	level: "level",
	path: "path",
	mask: "mask",
	meaning: "meaning",
	pageLink: "pageLink",
	postIt: "postIt",
	zIndex: "zIndex",
	block: "block",
	redLine: "redLine",
	textId: 'textId',
};

export const meaningKeyName = {
	"meaning-red-top": "上",
	"meaning-red-bottom": "下",
	"meaning-red-right": "右",
	"meaning-red-left": "左",
	"meaning-blue-top": "上",
	"meaning-blue-bottom": "下",
	"meaning-blue-right": "右",
	"meaning-blue-left": "左",
};

export const meaningBlueKey = {
	"右": "meaning-blue-right",
	"左": "meaning-blue-left",
	"上": "meaning-blue-top",
	"下": "meaning-blue-bottom",
};

export const meaningRedKey = {
	"右": "meaning-red-right",
	"左": "meaning-red-left",
	"上": "meaning-red-top",
	"下": "meaning-red-bottom",
};

// chapter
export const chapterPropJp = {
	タイトル: "name",
	始め: "firstPage",
	終わり: "lastPage",
};

export const chapterPropEn = {
	name: "名前",
	firstPage: "始め",
	lastPage: "終わり",
};

export const chapterConst = {
	idx: "idx",
	name: "name",
	firstPage: "firstPage",
	lastPage: "lastPage",
};

export const editorProp = {
	"type": "種類",
	"originId": "元ID",
	"id": "ID",
	"page": "ページ",
	"editor.ignore": "MY教科書エディタ登録",
	"css.position": "文字ボックス変更",
	"textId": "テキストID",
	"value": "テキスト",
	"css.fontSize": "テキストサイズ",
	"css.marginLeft": "X座標",
	"css.marginTop": "Y座標",
	"css.lineHeight": "行間隔",
	"css.color": "色",
	"css.fontWeight": "太字",
	"css.width": "横",
	"editor.myEditorImage": "R-ID(文字・背景画像)",
	"editor.myEditorImage_bg": "R-ID(背景画像)",
	"flag": "編集",
};

export const metaProp = {
	coverPath: "カバー経路",
	grade: "学年",
	semester: "学期区分",
	width: "サイズ(横)",
	subject: "教科名",
	bookId: "教科書ID",
	direction: "方向",
	height: "サイズ(縦)",
};

export const metaStr = {
	height: "height",
	width: "width",
};


export const editorIgnore = {
	true: "N",
	false: "Y",
};


export const editorPropEx = {
	ignore: "editor.ignore",
	fontSize: "css.fontSize",
	lineHeight: "css.lineHeight",
	marginLeft: "css.marginLeft",
	marginTop: "css.marginTop",
	left: "css.left",
	top: "css.top",
	width: "css.width",
	position: "css.position",
	fontWeight: "css.fontWeight",
	color: "css.color",
	originId: "originId",
};

export const editorStr = {
	objFlag: "obj.flag",
	objOriginId: "obj.originId",
	objPage: "obj.page",
	editor: 'editor',
	text: 'text',
	contents: "contents",
	ignore: "obj.editor.ignore",
	deFontSize: "obj.editor.text.defaultCss.fontSize",
	fontSize: "content.css.fontSize",
	lineHeight: "content.css.lineHeight",
	marginLeft: "content.css.marginLeft",
	marginTop: "content.css.marginTop",
	cssLineHeight : "css.lineHeight",
	cssFontSize : "css.fontSize",
	cssMarginTop : "css.marginTop",
	cssMarginLeft : "css.marginLeft",
	position: "obj.editor.text.css.position",
	dePosition : "obj.editor.text.defaultCss.position",
	objId: "obj.id",
	contentId: "content.id",
};


export const errorMsg = {
	idx: "番目の",
	onlyNumber: "は数字のみ入力してください。",
	incorrect: "が正しくありません。",
	required: "が必須入力です。",
	alreadyExists: "はすでに存在するんです。",
	isNotExists: "が存在しないんです。",
	chooseOne: "のなかから一つ選んで入力してください。",
	pleaseInput: "を入力してください。",
	noNeed: "は不要な入力です。",
	unmatchedColumn: "CSVのカラムが正しくありません。",
	tooLong: "が長すぎです。",
};

export const errType = {
	incorrect: `___番目の___が正しくありません。`,
	onlyNumber: `___番目の___は数字のみ入力してください。`,
	required: `___番目の___は必須入力です。`,
	requiredUpdate: `修正の際、___番目の___は必須入力です。`,
	isNotExists: `指定された___番目の___は存在しません。`,
	isExistBut: `___番目の___は存在しますが___ファイルが必要ですが___ファイルを呼びました。`,
	chooseOne: `___番目の___は___の中から一つ選択してください。`,
	notRequired: `___は不要な入力です。`,
	noInput: `___番目の___は不要な入力です。`,
	unmatchedColumn: `___のCSVカラムが正しくありません。`,
	overRange: `___番目の___は範囲内ではありません。`,
	unique: `___番目の___は中腹せれます。固有の値を入力してください。`,
	firstRow: `最初のローには___が必須入力です。`,
	tooLong: `___番目の___は長すぎるんです。`,
	inputUnique: `___番目の___は中腹入力です。`,
	gt: `___番目の___は___より高い数字を入力してください。`,
	chapterOrder: `___番目の___が正しくありません、順番は昇べきの順にしてください。`,
	noIdxIncorrect: `___が正しくありません。`,
	noIdxRequired: `___は必須入力です。`,
	noIdxChooseOne: `___は___の中から一つ選択してください。`,
	uniqueFromPage: `___番目の___は中腹せれます。___ページで同じ値が二つ以上ありますので、固有の値を入力してください。`,
	ascendingFromPage: `___ページの___は昇順で入力してください。`,
	overRangeDetail: `___番目の___は___から___の範囲内で入力してください。`,
	keyExistBut: `___番目の___は存在しますが、___または___が正しくありません。`,

};

export const defaultTextCss = {
	FONT_SIZE: "1em",
	LINE_HEIGHT: "135%",
	MARGIN_LEFT: "0%",
	MARGIN_TOP: "0%",
	TOP: "0%",
	LEFT: "0%",
	POSITION: "",
	SUBVIEW_HEIGHT: 100,
	SUBVIEW_WIDTH: 100,
	SUBVIEW_OPACITY: 0.5,
	FALSE: false,
};

export const videoProps = {
	"editor.ignore": "editor.ignore",
	"data.subtitleKey": "data.subtitleKey",
	"data.fileKey": "data.fileKey",
	page: "page",
	"css.left": "css.left",
	"css.top": "css.top",
	"options.link.zIndex": "options.link.zIndex",
	file: 'file',
};

export const csvMeta = {
	bookId: "教科書ID",
	subject: "教科名",
	grade: "学年",
	semester: "学期区分",
	totalPages: "ページ数",
	tooltip: "ツールチィプ",
	direction: "綴じ方向",
	"view.width":"サイズ(横)",
	"view.height":"サイズ(縦)",
	author: "作成者",
	coverPath: "カバー経路",
	lastModified: "最終修正日",
	nowEditing: "修正中",
	saved: "保存回数",
};

export const changeString = [
	{before: "㎥", after: "&#13221;"},
	{before: "㎠", after: "&#13216;"},
	{before: "㎤", after: "&#13220;"},
	{before: "㎢", after: "&#13218;"},
	{before: "㎛", after: "&#13211;"},
];

export const defaultAudioCss = {
	WIDTH: 100,
	HEIGHT: 100,
	TEXT_TOP: 10,
	TEXT_LEFT: 10,
	TEXT_WIDTH: 10,
	TEXT_HEIGHT: 10,
};

export const audioType = {
	AUDIO: "オーディオ",
	TEXT: "テキスト"
};

export const cssUnit = {
	PERCENT: "%",
	EM: "em",
};

export const displayMode = {
	kokugo: ['subView', 'icon', 'pageLink', 'meaning'],
	rika: ['subView', 'icon', 'pageLink', 'mask', 'block'],
	sansu: ['subView', 'icon', 'pageLink', 'mask'],
	shakai: ['subView', 'icon', 'pageLink', 'redLine', 'block'],
	seikatsu: ['subView', 'icon', 'pageLink'],
	shosha: ['subView', 'icon', 'pageLink'],
	doutoku: ['subView', 'icon', 'pageLink'],
	hoken: ['subView', 'icon', 'pageLink'],
	katei: ['subView', 'icon', 'pageLink'],
	eigo:  ['subView', 'icon', 'pageLink'],
};


export const iconForm = {
	kokugo: ['action', 'audio', 'document'],
	rika: ['action', 'pdf', 'map'],
	sansu: ['animation', 'resolve', 'simulation', 'worksheet'],
	shakai: ['work'],
	seikatsu: ['work'],
	shosha: ['work'],
	doutoku: ['work'],
	hoken: ['work'],
	katei: ['work'],
	eigo: ['work'],
};


export const subViewComment = {
	kokugo: [
		[`変更不可` ,`変更可能。`        ,`subView：標準モード`       　　　　　,``    ,``    ,``    ,`左右の位置です。`,`上下の位置です。`,``     ,`同じページ内の特定`  ,`0：なし`                 ,`0を基準として、値が`        ,`赤上線：上`    ,`青上線：上` ,`answer | content | content_common | external_link | external_link_common | flashcards | guide | page_link | reading_sound | related_document | related_document_common | simulation | simulation_common | slideshow | voice_common | work_sheet | movie | movie_common |`       ,`Y：有`    ,`contents：HTML`     ,``,`1：追加`],
		[``         ,``                 ,`icon：アイコン`            　　　　　,``    ,``    ,``    ,``               ,``                ,``     ,`部分にジャンプする`  ,`1：表面`                 ,`大きいものほど上に`         ,`赤下線：下`    ,`青下線：下` ,``     						,`N：無`    ,`pdf：PDF`           ,``,`2：修正`],
		[``         ,``                 ,`pageLink：ページリンク`    　　　　　,``    ,``    ,``    ,``               ,``                ,``     ,`リンクのことです。`  ,`2：レイヤー`             ,`なります。`                 ,`赤左線：左`    ,`青左線：左` ,``         					,``         ,`link: 外部リンク`                  ,``,`3：削除`],
		[``         ,``                 ,`meaning：(国語)意味段落、形式段落`　　,``    ,``    ,``    ,``              ,``                ,``     ,``                   ,`(クリックポイント)`       ,``                          ,`赤右線：右`    ,`青右線：右` ,``                            ,``         ,``                  ,``,``],
		[``         ,``                 ,`` 　　　　　　　　　　　　　　　　　　,``    ,``    ,``    ,``              ,``                ,``     ,``                    ,`3：両方`                 ,``                          ,``              ,``           ,``                           ,``         ,``                   ,``,``],
		[``         ,``                 ,`` 　　　　　　　　　　　　　　　　　　,``    ,``    ,``    ,``              ,``                ,``     ,``                    ,`(アイコン)`              ,``                          ,``              ,``           ,``                           ,``         ,``                   ,``,``]
	],
	rika: [
		[`変更不可`  ,`変更可能。`       ,`subView：標準モード`           　,``   ,``    ,``    ,`左右の位置です。`,`上下の位置です。`,``    ,`同じページ内の特定`      ,`0：なし`               ,`0を基準として、値が`     ,`赤上線：上`    ,`青上線：上` ,`answer | content | content_common | external_link | external_link_common | flashcards | guide | page_link | reading_sound | related_document | related_document_common | simulation | simulation_common | slideshow | voice_common | work_sheet | movie | movie_common |`          ,`Y：有`        ,`contents：HTML`        ,``,`1：追加`],
		[``         ,``                 ,`icon：アイコン`                　,``   ,``    ,``    ,``               ,``                ,``    ,`部分にジャンプする`      ,`1：表面`               ,`大きいものほど上に`      ,`赤下線：下`    ,`青下線：下` ,``        				   ,`N：無`        ,`pdf：PDF`     　　 　　,``,`2：修正`],
		[``         ,``                 ,`pageLink：ページリンク`        　,``   ,``    ,``    ,``               ,``                ,``    ,`リンクのことです。`      ,`2：レイヤー`           ,`なります。`              ,`赤左線：左`    ,`青左線：左` ,``      　　　　			   ,``            ,`link: 外部リンク`                   　　,``,`3：削除`],
		[``         ,``                 ,`mask： (理科）キャラクター発言`  ,``   ,``    ,``    ,``               ,``                ,``    ,``                       ,`(クリックポイント)`     ,``                       ,`赤右線：右`    ,`青右線：右` ,``       　 				   ,``             ,``      　　　　　       ,``,``],
		[``         ,``                 ,`block:(理科、社会) ブロック`     ,``    ,``    ,``   ,``               ,``                 ,``    ,``                      ,`3：両方`               ,``                       ,``              ,``           ,``                        ,``             ,``                      ,``,``],
		[``         ,``                 ,``                                ,``    ,``    ,``   ,``               ,``                 ,``    ,``                      ,`(アイコン)`           ,``                       ,``              ,``           ,``                        ,``             ,``                      ,``,``]
	],
	sansu: [
		[`変更不可`  ,`変更可能。`       ,`subView：標準モード`           ,``   ,``    ,``    ,`左右の位置です。`,`上下の位置です。`,``    ,`同じページ内の特定`      ,`0：なし`             ,`0を基準として、値が`      ,`赤上線：上`    ,`青上線：上`    ,`answer | content | content_common | external_link | external_link_common | flashcards | guide | page_link | reading_sound | related_document | related_document_common | simulation | simulation_common | slideshow | voice_common | work_sheet | movie | movie_common |`            ,`Y：有`        ,`contents：HTML`        ,``,`1：追加`],
		[``         ,``                 ,`icon：アイコン`                ,``   ,``    ,``    ,``               ,``                ,``    ,`部分にジャンプする`      ,`1：表面`             ,`大きいものほど上に`       ,`赤下線：下`    ,`青下線：下`    ,``                        ,`N：無`        ,`pdf：PDF`              ,``,`2：修正`],
		[``         ,``                 ,`pageLink：ページリンク`        ,``   ,``    ,``    ,``               ,``                ,``    ,`リンクのことです。`      ,`2：レイヤー`         ,`なります。`               ,`赤左線：左`    ,`青左線：左`    ,``         				,``            ,`link: 外部リンク`                       ,``,`3：削除`],
		[``         ,``                 ,`postIt： (算数) めくり紙`      ,``   ,``    ,``    ,``               ,``                ,``    ,``                       ,`(クリックポイント)`  ,``                         ,`赤右線：右`    ,`青右線：右`    ,``               			,``            ,``                      ,``,``],
		[``         ,``                 ,``                             ,``    ,``    ,``    ,``               ,``                ,``    ,``                       ,`3：両方`             ,``                        ,``              ,``             ,``                           ,``            ,``                      ,``,``],
		[``         ,``                 ,``                             ,``    ,``    ,``    ,``               ,``                ,``    ,``                       ,`(アイコン)`          ,``                        ,``              ,``             ,``                           ,``            ,``                      ,``,``]
	],
	shakai: [
		[`変更不可`  ,`変更可能。`       ,`subView：標準モード`           ,``   ,``    ,``    ,`左右の位置です。`,`上下の位置です。`,``    ,`同じページ内の特定`      ,`0：なし`             ,`0を基準として、値が`     ,`赤上線：上`    ,`青上線：上`       ,`answer | content | content_common | external_link | external_link_common | flashcards | guide | page_link | reading_sound | related_document | related_document_common | simulation | simulation_common | slideshow | voice_common | work_sheet | movie | movie_common |`          　 ,`Y：有`      ,`contents：HTML`        ,``,`1：追加`],
		[``         ,``                 ,`icon：アイコン`                ,``   ,``    ,``    ,``               ,``                ,``    ,`部分にジャンプする`      ,`1：表面`             ,`大きいものほど上に`      ,`赤下線：下`    ,`青下線：下`       ,``          				,`N：無`      ,`pdf：PDF`              ,``,`2：修正`],
		[``         ,``                 ,`pageLink：ページリンク`        ,``   ,``    ,``    ,``               ,``                ,``    ,`リンクのことです。`      ,`2：レイヤー`         ,`なります。`              ,`赤左線：左`    ,`青左線：左`       ,``       　                 ,``           ,`link: 外部リンク`                      ,``,`3：削除`],
		[``         ,``                 ,`redLine： (社会) 赤線`         ,``   ,``    ,``    ,``               ,``                ,``    ,``                       ,`(クリックポイント)`  ,``                        ,`赤右線：右`    ,`青右線：右`       ,``            　            ,``            ,``                      ,``,``],
		[``         ,``                 ,`block:(社会、理科) ブロック`    ,``    ,``    ,``    ,``               ,``                ,``    ,``                       ,`3：両方`            ,``                       ,``              ,``                ,``                          ,``            ,``                      ,``,``],
		[``         ,``                 ,`block:(社会、理科) ブロック`    ,``    ,``    ,``    ,``               ,``                ,``    ,``                       ,`(アイコン)`         ,``                       ,``              ,``                ,``                          ,``            ,``                      ,``,``]
	],
	seikatsu: [
		[`変更不可`  ,`変更可能。`       ,`subView：標準モード`           ,``   ,``    ,``    ,`左右の位置です。`,`上下の位置です。`,``    ,`同じページ内の特定`      ,`0：なし`             ,`0を基準として、値が`     ,`赤上線：上`    ,`青上線：上`        ,`answer | content | content_common | external_link | external_link_common | flashcards | guide | page_link | reading_sound | related_document | related_document_common | simulation | simulation_common | slideshow | voice_common | work_sheet | movie | movie_common |`         ,`Y：有`        ,``        ,``,`1：追加`],
		[``         ,``                 ,`icon：アイコン`                ,``   ,``    ,``    ,``               ,``                ,``    ,`部分にジャンプする`      ,`1：表面`             ,`大きいものほど上に`      ,`赤下線：下`    ,`青下線：下`        ,``                     ,`N：無`        ,``        ,``,`2：修正`],
		[``         ,``                 ,`pageLink：ページリンク`        ,``   ,``    ,``    ,``               ,``                ,``    ,`リンクのことです。`      ,`2：レイヤー`         ,`なります。`              ,`赤左線：左`    ,`青左線：左`        ,``                      ,``            ,``         ,``,`3：削除`],
		[``         ,``                 ,``      　　　　　　　　　　　　 ,``   ,``    ,``    ,``               ,``                ,``    ,``                       ,`(クリックポイント)`  ,``                       ,`赤右線：右`    ,`青右線：右`        ,``                      ,``            ,``         ,``,``],
		[``         ,``                 ,``                             ,``    ,``    ,``    ,``               ,``                ,``    ,``                       ,`3：両方`             ,``                       ,``              ,``                 ,``                     ,``            ,``          ,``,``],
		[``         ,``                 ,``                             ,``    ,``    ,``    ,``               ,``                ,``    ,``                       ,`(アイコン)`          ,``                       ,``              ,``                 ,``                     ,``            ,``          ,``,``]
	],
	shosha: [
		[`変更不可`  ,`変更可能。`       ,`subView：標準モード`           ,``   ,``    ,``    ,`左右の位置です。`,`上下の位置です。`,``    ,`同じページ内の特定`      ,`0：なし`             ,`0を基準として、値が`     ,`赤上線：上`    ,`青上線：上`        ,`answer | content | content_common | external_link | external_link_common | flashcards | guide | page_link | reading_sound | related_document | related_document_common | simulation | simulation_common | slideshow | voice_common | work_sheet | movie | movie_common |`         ,`Y：有`        ,``        ,``,`1：追加`],
		[``         ,``                 ,`icon：アイコン`                ,``   ,``    ,``    ,``               ,``                ,``    ,`部分にジャンプする`      ,`1：表面`             ,`大きいものほど上に`      ,`赤下線：下`    ,`青下線：下`        ,``                     ,`N：無`        ,``        ,``,`2：修正`],
		[``         ,``                 ,`pageLink：ページリンク`        ,``   ,``    ,``    ,``               ,``                ,``    ,`リンクのことです。`      ,`2：レイヤー`         ,`なります。`              ,`赤左線：左`    ,`青左線：左`        ,``                      ,``            ,``         ,``,`3：削除`],
		[``         ,``                 ,``      　　　　　　　　　　　　 ,``   ,``    ,``    ,``               ,``                ,``    ,``                       ,`(クリックポイント)`  ,``                       ,`赤右線：右`    ,`青右線：右`        ,``                      ,``            ,``        ,``,``],
		[``         ,``                 ,``                             ,``    ,``    ,``    ,``               ,``                ,``    ,``                       ,`3：両方`             ,``                       ,``              ,``                 ,``                     ,``            ,``         ,``,``],
		[``         ,``                 ,``                             ,``    ,``    ,``    ,``               ,``                ,``    ,``                       ,`(アイコン)`          ,``                       ,``              ,``                 ,``                     ,``            ,``         ,``,``]
	],
	doutoku: [
		[`変更不可`  ,`変更可能。`       ,`subView：標準モード`           ,``   ,``    ,``    ,`左右の位置です。`,`上下の位置です。`,``    ,`同じページ内の特定`      ,`0：なし`             ,`0を基準として、値が`     ,`赤上線：上`    ,`青上線：上`    ,`answer | content | content_common | external_link | external_link_common | flashcards | guide | page_link | reading_sound | related_document | related_document_common | simulation | simulation_common | slideshow | voice_common | work_sheet | movie | movie_common |`         ,`Y：有`        ,``        ,``,`1：追加`],
		[``         ,``                 ,`icon：アイコン`                ,``   ,``    ,``    ,``               ,``                ,``    ,`部分にジャンプする`      ,`1：表面`             ,`大きいものほど上に`      ,`赤下線：下`    ,`青下線：下`    ,``                     ,`N：無`        ,``        ,``,`2：修正`],
		[``         ,``                 ,`pageLink：ページリンク`        ,``   ,``    ,``    ,``               ,``                ,``    ,`リンクのことです。`      ,`2：レイヤー`         ,`なります。`              ,`赤左線：左`    ,`青左線：左`    ,``                      ,``            ,``         ,``,`3：削除`],
		[``         ,``                 ,``      　　　　　　　　　　　　 ,``   ,``    ,``    ,``               ,``                ,``    ,``                       ,`(クリックポイント)`  ,``                       ,`赤右線：右`    ,`青右線：右`    ,``                      ,``            ,``         ,``,``],
		[``         ,``                 ,``                             ,``    ,``    ,``    ,``               ,``                ,``    ,``                       ,`3：両方`             ,``                       ,``              ,``             ,``                     ,``            ,``         ,``,``],
		[``         ,``                 ,``                             ,``    ,``    ,``    ,``               ,``                ,``    ,``                       ,`(アイコン)`          ,``                       ,``              ,``             ,``                     ,``            ,``         ,``,``]
	],
	hoken: [
		[`変更不可`  ,`変更可能。`       ,`subView：標準モード`           ,``   ,``    ,``    ,`左右の位置です。`,`上下の位置です。`,``    ,`同じページ内の特定`      ,`0：なし`             ,`0を基準として、値が`      ,`赤上線：上`    ,`青上線：上`    ,`answer | content | content_common | external_link | external_link_common | flashcards | guide | page_link | reading_sound | related_document | related_document_common | simulation | simulation_common | slideshow | voice_common | work_sheet | movie | movie_common |`         ,`Y：有`        ,``        ,``,`1：追加`],
		[``         ,``                 ,`icon：アイコン`                ,``   ,``    ,``    ,``               ,``                ,``    ,`部分にジャンプする`      ,`1：表面`             ,`大きいものほど上に`       ,`赤下線：下`    ,`青下線：下`    ,``                     ,`N：無`        ,``        ,``,`2：修正`],
		[``         ,``                 ,`pageLink：ページリンク`        ,``   ,``    ,``    ,``               ,``                ,``    ,`リンクのことです。`      ,`2：レイヤー`         ,`なります。`               ,`赤左線：左`    ,`青左線：左`    ,``                      ,``            ,``         ,``,`3：削除`],
		[``         ,``                 ,``      　　　　　　　　　　　　 ,``   ,``    ,``    ,``               ,``                ,``    ,``                       ,`(クリックポイント)`  ,``                        ,`赤右線：右`    ,`青右線：右`    ,``                      ,``            ,``         ,``,``],
		[``         ,``                 ,``                             ,``    ,``    ,``    ,``               ,``                ,``    ,``                       ,`3：両方`             ,``                        ,``              ,``              ,``                     ,``            ,``         ,``,``],
		[``         ,``                 ,``                             ,``    ,``    ,``    ,``               ,``                ,``    ,``                       ,`(アイコン)`          ,``                        ,``              ,``              ,``                     ,``            ,``         ,``,``]
	],
	katei: [
		[`変更不可`  ,`変更可能。`       ,`subView：標準モード`           ,``   ,``    ,``    ,`左右の位置です。`,`上下の位置です。`,``    ,`同じページ内の特定`      ,`0：なし`             ,`0を基準として、値が`     ,`赤上線：上`    ,`青上線：上`      ,`answer | content | content_common | external_link | external_link_common | flashcards | guide | page_link | reading_sound | related_document | related_document_common | simulation | simulation_common | slideshow | voice_common | work_sheet | movie | movie_common |`         ,`Y：有`        ,``        ,``,`1：追加`],
		[``         ,``                 ,`icon：アイコン`                ,``   ,``    ,``    ,``               ,``                ,``    ,`部分にジャンプする`      ,`1：表面`             ,`大きいものほど上に`      ,`赤下線：下`    ,`青下線：下`      ,``                     ,`N：無`        ,``        ,``,`2：修正`],
		[``         ,``                 ,`pageLink：ページリンク`        ,``   ,``    ,``    ,``               ,``                ,``    ,`リンクのことです。`      ,`2：レイヤー`         ,`なります。`              ,`赤左線：左`    ,`青左線：左`      ,``                      ,``            ,``         ,``,`3：削除`],
		[``         ,``                 ,``      　　　　　　　　　　　　 ,``   ,``    ,``    ,``               ,``                ,``    ,``                       ,`(クリックポイント)`  ,``                       ,`赤右線：右`    ,`青右線：右`      ,``                      ,``            ,``         ,``,``],
		[``         ,``                 ,``                             ,``    ,``    ,``    ,``               ,``                ,``    ,``                       ,`3：両方`             ,``                       ,``              ,``                ,``                     ,``            ,``         ,``,``],
		[``         ,``                 ,``                             ,``    ,``    ,``    ,``               ,``                ,``    ,``                       ,`(アイコン)`          ,``                       ,``              ,``                ,``                     ,``            ,``         ,``,``]
	],
	eigo: [
		[`変更不可`  ,`変更可能。`       ,`subView：標準モード`           ,``   ,``    ,``    ,`左右の位置です。`,`上下の位置です。`,``    ,`同じページ内の特定`      ,`0：なし`             ,`0を基準として、値が`     ,`赤上線：上`    ,`青上線：上`      ,`answer | content | content_common | external_link | external_link_common | flashcards | guide | page_link | reading_sound | related_document | related_document_common | simulation | simulation_common | slideshow | voice_common | work_sheet | movie | movie_common |`         ,`Y：有`        ,``        ,``,`1：追加`],
		[``         ,``                 ,`icon：アイコン`                ,``   ,``    ,``    ,``               ,``                ,``    ,`部分にジャンプする`      ,`1：表面`             ,`大きいものほど上に`      ,`赤下線：下`    ,`青下線：下`      ,``                     ,`N：無`        ,``        ,``,`2：修正`],
		[``         ,``                 ,`pageLink：ページリンク`        ,``   ,``    ,``    ,``               ,``                ,``    ,`リンクのことです。`      ,`2：レイヤー`         ,`なります。`              ,`赤左線：左`    ,`青左線：左`      ,``                      ,``            ,``         ,``,`3：削除`],
		[``         ,``                 ,``      　　　　　　　　　　　　 ,``   ,``    ,``    ,``               ,``                ,``    ,``                       ,`(クリックポイント)`  ,``                       ,`赤右線：右`    ,`青右線：右`      ,``                      ,``            ,``         ,``,``],
		[``         ,``                 ,``                             ,``    ,``    ,``    ,``               ,``                ,``    ,``                       ,`3：両方`             ,``                       ,``              ,``                ,``                     ,``            ,``         ,``,``],
		[``         ,``                 ,``                             ,``    ,``    ,``    ,``               ,``                ,``    ,``                       ,`(アイコン)`          ,``                       ,``              ,``                ,``                     ,``            ,``         ,``,``]
	]
};

export const csvTypeComment = {
	book: [
		[`教科書IDは変更しないでください。`  ,`「国語：kokugo」`       ,`「上期：1」`      ,`「1年：1」`    , `サイズはpx単位です。` , `サイズはpx単位です。`,`「右綴じ：rtl」`],
		[``                                 ,`「理科：rika」`         ,`「下期：2」`      ,`「2年：2」`    ,``                     ,``                    ,`「左綴じ：ltr」`],
		[``                                 ,`「算数：sansu」`        ,``                 ,`「3年：3」`    ,``                     ,``                    ,``               ],
		[``                                 ,`「生活：seikatsu」`     ,``                 ,`「4年：4」`    ,``                     ,``                    ,``               ],
		[``                                 ,`「社会：shakai」`       ,``                 ,`「5年：5」`    ,``                     ,``                    ,``               ],
		[``                                 ,`「書写：shosha」`       ,``                 ,`「6年：6」`    ,``                     ,``                    ,``               ],
		[``                                 ,`「道徳：doutoku」`     ,``                ,``              ,``                     ,``                    ,``               ],
		[``                                 ,`「保健：hoken」`       ,``                ,``              ,``                     ,``                    ,``               ],
		[``                                 ,`「家庭：katei」`       ,``                ,``              ,``                     ,``                    ,``               ],
		[``                                 ,`「英語：eigo」`       ,``                ,``              ,``                     ,``                    ,``               ],
	],
	chapter: [
		[`順番: チャプターの順番。1から昇順に設定してください。数字のみ入力可能です。`],
	],
	editor: [
		[`「種類」は必須です。`         ,`変更不可`      ,`変更可能`             ,``           ,`T-Frameビューア画面`                            ,``,``     ,``     ,`左右の位置です。`     ,`上下の位置です。`     ,`行間隔はテキスト`          ,`#000000:black`,``,``       ,``,``        ,`1:追加`],
		[`・editor（画像）`             ,``             ,``                     ,``           ,`MY教科書エディタ⇒ボタン【現在のページをす`      ,``,``     ,``     ,``                    ,``                    ,`全体の行の間隔です。`      ,`#FFFFFF:white`,``,``       ,``,``        ,`2:修正`],
		[`text（editor要素内に位置す`   ,``             ,``                     ,``           ,`べて追加する】をクリック時にオブジェクトを追`     ,``,``     ,``     ,``                    ,``                    ,``                         ,`#F5921D:orange`,``,``       ,``,``        ,`3:削除`],
		[`るテキスト）`                 ,``             ,``                     ,``           ,`加する・追加しないの項目です。`                  ,``,``     ,``     ,``                    ,``                    ,``                         ,`#FF0000:red`,``,``       ,``,``        ,``],
		[``                             ,``             ,``                     ,``           ,``                                              ,``,``     ,``     ,``                    ,``                    ,``                         ,`#00FF00:green`,``,``       ,``,``        ,``],
		[``                             ,``             ,``                     ,``           ,`Y:有`                                          ,`Y:変更`,``     ,``     ,``                    ,``                    ,``                         ,`#0000FF:blue`,`Y:太字`,``       ,``,``        ,``],
		[``                             ,``             ,``                     ,``           ,`N:無`                                          ,``,``     ,``     ,``                    ,``                    ,``                         ,``,``,``       ,``,``        ,``]
	],
	audio: [
		[`「種類」は必須です。「オーディオ」と「テキスト」の種類があります。`],
		[`「オーディオ」は「元ID」、「ページ」、「再生順番」の入力が必須です。`],
		[`「オーディオ」の「再生順番」は昇順で数字のみ入力してください。`],
		[`「テキスト」は「カラオケ順番」、「再生時間」、「待機時間」の入力が必須です。`]
	],
	html: [
		[`変更不可`,``,    `HTMLコンテンツからアップロードしたファイルIDを入力します。`]
	],
	video: [
		[`変更不可`      ,``        ,``    ,`左右の位置です。`  ,`上下の位置です。`   ,`リポジトリからアップ`          ,`リポジトリからアップ`           ,`動画アイコンの吹き出しです。`     ,`動画のタイトルです。`,``   ,`1：追加`],
		[``              ,``        ,``    ,``                 ,``                  ,`ロードした`                    ,`ロードした`                     ,``                               ,``,``                      ,`2：修正`],
		[``              ,``        ,``    ,``                 ,``                  ,`ファイルIDを入力します。`       ,`ファイルIDを入力します。`       ,``                               ,``,``                      ,`3：削除`]
	],
}
